@import '@/index.scss';

.initials-avatar {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  background-color: $theme-primary;
  display: inline-block;
  box-sizing: border-box;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-style: inherit;
  font-weight: inherit;
  font-size: 0.85rem;
  line-height: 1rem;
  vertical-align: text-top;
}
