@import "./core/scss/mdb.core.scss";
@import "./core/scss/mdb.pro.scss";

$theme-primary: #f75a26; // theme primary color, change this value to customize theme
$primary: #f75a26; // theme primary color, change this value to customize theme
$theme-secondary: #423e3d; // theme secondary color, change this value to customize theme
$secondary: #423e3d; // theme secondary color, change this value to customize theme

// Light theme defaults
$mdb-theme-light-background: #F1F3F4;
$mdb-theme-light-on-background: #4f4f4f;
$mdb-theme-light-surface: #ffffff;
$mdb-theme-light-on-surface: #262626;
$mdb-theme-light-on-primary: #ffffff;
$mdb-theme-light-on-secondary: #ffffff;
$mdb-theme-light-on-surface-variant: rgba(255, 255, 255, 0.75);
$mdb-theme-light-on-surface-hover: #464646;
$mdb-theme-light-error: #C71026;
$mdb-theme-light-on-error: #ffffff;
$mdb-theme-light-success: #0B8A00;
$mdb-theme-light-on-success: #ffffff;
$mdb-theme-light-warning: #FFC565;
$mdb-theme-light-info: #5DAAEE;
$mdb-theme-light-surface-hover: #ddd;
$mdb-theme-light-link-background-hover: rgba(0, 0, 0, 0.01);
$mdb-theme-light-divider: #e0e0e0;
$mdb-theme-light-dropdown-hover: rgba(0, 0, 0, 0.04);
$mdb-theme-light-dropdown-disabled: #757575;
$mdb-theme-light-dropdown-text: #757575;
$mdb-theme-light-dropdown-header: #757575;
$mdb-theme-light-tooltip-background: #757575;
$mdb-theme-light-on-hover: #f5f5f5;
$mdb-theme-light-timepicker-clock-bg: rgba(0, 0, 0, 0.07);
$mdb-theme-light-timepicker-clock-text: #4f4f4f;
$mdb-theme-light-surface-highlight: #eee;
$mdb-theme-light-input-border: #bdbdbd;
$mdb-theme-light-input-label: rgba(0, 0, 0, 0.6);
$mdb-theme-light-input-text: #616161;
$mdb-theme-light-input-placeholder: #6c757d;
$mdb-theme-light-input-readonly: #e9ecef;
$mdb-theme-light-pill-background: #f5f5f5;
$mdb-theme-light-pill-text: rgba(0, 0, 0, 0.6);
$mdb-theme-light-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
$mdb-theme-light-shadow-variant: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
$mdb-theme-light-input-background: #000;

// Dark theme defaults
$mdb-theme-dark-background: #0d0f11;
$mdb-theme-dark-on-background: #ffffff;
$mdb-theme-dark-surface: #191d23;
$mdb-theme-dark-on-primary: #ffffff;
$mdb-theme-dark-on-secondary: #ffffff;
$mdb-theme-dark-on-surface: #ffffff;
$mdb-theme-dark-on-surface-variant: rgba(0, 0, 0, 0.65);
$mdb-theme-dark-on-surface-hover: #d3d3d3;
$mdb-theme-dark-error: #F53B30;
$mdb-theme-dark-on-error: #ffffff;
$mdb-theme-dark-success: #2AA31F;
$mdb-theme-dark-on-success: #ffffff;
$mdb-theme-dark-warning: #EFB047;
$mdb-theme-dark-info: #5395CF;
$mdb-theme-dark-surface-hover: rgba(255, 255, 255, 0.15);
$mdb-theme-dark-picker-header: #323232;
$mdb-theme-dark-link-background-hover: rgba(0, 0, 0, 0.15);
$mdb-theme-dark-divider: rgba(255, 255, 255, 0.12);
$mdb-theme-dark-dropdown-hover: rgba(255, 255, 255, 0.04);
$mdb-theme-dark-dropdown-disabled: #adb5bd;
$mdb-theme-dark-dropdown-text: #dee2e6;
$mdb-theme-dark-dropdown-header: #adb5bd;
$mdb-theme-dark-tooltip-background: #757575;
$mdb-theme-dark-on-hover: rgba(0, 0, 0, 0.15);
$mdb-theme-dark-timepicker-clock-bg: rgba(50, 50, 50, 0.3);
$mdb-theme-dark-timepicker-clock-text: #ffffff;
$mdb-theme-dark-surface-highlight: rgba(255, 255, 255, 0.3);
$mdb-theme-dark-input-border: rgba(255, 255, 255, 0.7);
$mdb-theme-dark-input-label: rgba(255, 255, 255, 0.7);
$mdb-theme-dark-input-text: rgba(255, 255, 255, 0.7);
$mdb-theme-dark-input-placeholder: rgba(255, 255, 255, 0.7);
$mdb-theme-dark-input-readonly: rgba(255, 255, 255, 0.2);
$mdb-theme-dark-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
$mdb-theme-dark-shadow-variant: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
$mdb-theme-dark-input-background: #fff;
$mdb-theme-dark-pill-background: #0d0d0d;
$mdb-theme-dark-pill-text: #ffffff;
$mdb-theme-dark-modal-button-color: invert(1) grayscale(100%) brightness(200%);

$form-outline-select-label-color: $theme-primary;
$form-outline-select-notch-border-color: $theme-primary;

// include theme styles
@include mdb-theme((primary: $theme-primary,
    secondary: $theme-secondary,
    surface: $mdb-theme-light-surface,
    onSurface: $mdb-theme-light-on-surface,
    onSurfaceVariant: $mdb-theme-light-on-surface-variant,
    surfaceHighlight: $mdb-theme-light-surface-highlight,
    surfaceHover: $mdb-theme-light-surface-hover,
    surfaceDisabled: $mdb-theme-light-dropdown-disabled,
    background: $mdb-theme-light-background,
    onBackground: $mdb-theme-light-on-background,
    onPrimary: $mdb-theme-light-on-primary,
    onSecondary: $mdb-theme-light-on-secondary,
    error: $mdb-theme-light-error,
    onError: $mdb-theme-light-on-error,
    success: $mdb-theme-light-success,
    onSuccess: $mdb-theme-light-on-success,
    warning: $mdb-theme-light-warning,
    info: $mdb-theme-light-info,
    link: $theme-primary,
    onLink: $mdb-theme-light-link-background-hover,
    divider: $mdb-theme-light-divider,
    onHover: $mdb-theme-light-on-hover,
    // Datepicker and timepicker
    pickerHeader: $theme-primary,
    // Dropdown
    dropdownText: $mdb-theme-light-dropdown-text,
    // Tooltip
    tooltipBackground: $mdb-theme-light-tooltip-background,
    // Scrollspy
    scrollspyActive: $theme-primary,
    // Sidenav
    sidenavPrimaryText: $theme-primary,
    sidenavPrimaryBg: rgba($theme-primary, 0.1),
    sidenavSecondaryText: $theme-secondary,
    sidenavSecondaryBg: rgba($theme-secondary, 0.1),
    // Timepicker
    timepickerClockBg: $mdb-theme-light-timepicker-clock-bg,
    timepickerClockText: $mdb-theme-light-timepicker-clock-text,
    // Input
    inputBorder: $mdb-theme-light-input-border,
    inputLabel: $mdb-theme-light-input-label,
    inputText: $mdb-theme-light-input-text,
    inputPlaceholder: $mdb-theme-light-input-placeholder,
    inputReadonly: $mdb-theme-light-input-readonly,
    cardShadow: $mdb-theme-light-shadow,
    toastShadow: $mdb-theme-light-shadow-variant,
    inputBackground: $mdb-theme-light-input-background,
    // Pills
    pillBackground: $mdb-theme-light-pill-background,
    pillText: $mdb-theme-light-pill-text,
  ));

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $mdb-theme-light-background;
}

.dark {
  @include mdb-theme((name: 'dark',
      primary: $theme-primary,
      secondary: $theme-secondary,
      surface: $mdb-theme-dark-surface,
      onSurface: $mdb-theme-dark-on-surface,
      onSurfaceVariant: $mdb-theme-dark-on-surface-variant,
      surfaceHighlight: $mdb-theme-dark-surface-highlight,
      surfaceHover: $mdb-theme-dark-surface-hover,
      surfaceDisabled: $mdb-theme-dark-dropdown-disabled,
      background: $mdb-theme-dark-background,
      onBackground: $mdb-theme-dark-on-background,
      onPrimary: $mdb-theme-dark-on-primary,
      onSecondary: $mdb-theme-dark-on-secondary,
      error: $mdb-theme-dark-error,
      onError: $mdb-theme-dark-on-error,
      success: $mdb-theme-dark-success,
      onSuccess: $mdb-theme-dark-on-success,
      warning: $mdb-theme-dark-warning,
      info: $mdb-theme-dark-info,
      link: $theme-primary,
      onLink: $mdb-theme-dark-link-background-hover,
      divider: $mdb-theme-dark-divider,
      onHover: $mdb-theme-dark-on-hover,
      modalButtonColor: $mdb-theme-dark-modal-button-color,
      // Datepicker and timepicker
      pickerHeader: $mdb-theme-dark-picker-header,
      // Dropdown
      dropdownText: $mdb-theme-dark-dropdown-text,
      // Tooltip
      tooltipBackground: $mdb-theme-dark-tooltip-background,
      // Scrollspy
      scrollspyActive: lighten($theme-primary, 20%),
      // Sidenav
      sidenavPrimaryText: lighten($theme-primary, 10%),
      sidenavPrimaryBg: rgba($theme-primary, 0.1),
      sidenavSecondaryText: lighten($theme-secondary, 10%),
      sidenavSecondaryBg: rgba($theme-secondary, 0.1),
      // Timepicker
      timepickerClockBg: $mdb-theme-dark-timepicker-clock-bg,
      timepickerClockText: $mdb-theme-dark-timepicker-clock-text,
      // Input
      inputBorder: $mdb-theme-dark-input-border,
      inputLabel: $mdb-theme-dark-input-label,
      inputText: $mdb-theme-dark-input-text,
      inputPlaceholder: $mdb-theme-light-input-placeholder,
      inputReadonly: $mdb-theme-dark-input-readonly,
      cardShadow: $mdb-theme-dark-shadow,
      toastShadow: $mdb-theme-dark-shadow-variant,
      inputBackground: $mdb-theme-dark-input-background,
      // Pills
      pillBackground: $mdb-theme-dark-pill-background,
      pillText: $mdb-theme-dark-pill-text,
      progressBackground: $mdb-theme-dark-surface,
    ));

  .navbar {
    background-color: $mdb-theme-dark-surface;
  }

  %loading-skeleton {
    background-color: $mdb-theme-dark-surface-hover !important;
    border-color: transparent !important;
    color: transparent !important;
  }
}

.navbar {
  background-color: $mdb-theme-light-surface;
  padding-left: 240px;
  height: 58px;
}

@media (max-width: 1440px) {
  .navbar {
    padding-left: 0;
  }
}

.main {
  padding-left: 0px;
}

@media (min-width: 1440px) {
  .main {
    padding-left: 240px;
  }
}

.navbar-expand-xs {
  flex-wrap: nowrap;
  justify-content: flex-start;

  .navbar-nav {
    flex-direction: row;

    .dropdown-menu {
      position: absolute;
    }

    .nav-link {
      padding-right: var(--#{$prefix}navbar-nav-link-padding-x);
      padding-left: var(--#{$prefix}navbar-nav-link-padding-x);
    }
  }

  .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-collapse {
    display: flex !important; // stylelint-disable-line declaration-no-important
    flex-basis: auto;
  }

  @media (min-width: 1440px) {
    .navbar-toggler {
      display: none;
    }
  }

  .offcanvas {
    // stylelint-disable declaration-no-important
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    @include box-shadow(none);
    @include transition(none);
    // stylelint-enable declaration-no-important

    .offcanvas-header {
      display: none;
    }

    .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
    }
  }
}

.text-transform-none {
  text-transform: none !important;
}

// Bootstrap Loading Skeleton
%loading-skeleton {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;

  &::placeholder {
    color: transparent;
  }
}
@keyframes loading-skeleton {
  from {
    opacity: .4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
  
  img {
    filter: grayscale(100) contrast(0%) brightness(1.8);
  }
  h1, h2, h3, h4, h5, h6,
  p, li,
  .btn,
  label,
  .form-control {
    @extend %loading-skeleton;
  }
}

.cursor-pointer {
  cursor: pointer !important
}

.cursor-na {
  cursor: not-allowed !important
}

.cursor-zoom-in {
  cursor: zoom-in !important
}

.cursor-zoom-out {
  cursor: zoom-out !important
}

.was-validated .form-control:valid, .form-control.is-valid {
  margin-bottom: 0;
}

.border-top-primary {
  border-top: 1px solid #1c244b !important;
}

.border-top-5 {
  border-top-width: 5px !important;
}

.btn-lg {
  padding-top: $input-padding-top;
  padding-bottom: $input-padding-bottom;
  padding-left: $input-padding-left;
  padding-right: $input-padding-right;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  text-transform: none;
}

.form-outline {
  --#{$prefix}form-outline-select-notch-border-color: #{$form-outline-select-notch-border-color};
}

.select-wrapper {
  --#{$prefix}form-outline-select-arrow-color: #{$form-outline-select-arrow-color};
  --#{$prefix}form-outline-select-arrow-font-size: #{$form-outline-select-arrow-font-size};
  --#{$prefix}form-outline-select-arrow-top: #{$form-outline-select-arrow-top};
  --#{$prefix}form-outline-select-arrow-right: #{$form-outline-select-arrow-right};
  --#{$prefix}form-outline-select-valid-color: #{$form-outline-select-valid-color};
  --#{$prefix}form-outline-select-invalid-color: #{$form-outline-select-invalid-color};
  --#{$prefix}form-outline-select-clear-btn-color: #{$form-outline-select-clear-btn-color};
  --#{$prefix}form-outline-select-clear-btn-font-size: #{$form-outline-select-clear-btn-font-size};
  --#{$prefix}form-outline-select-clear-btn-top: #{$form-outline-select-clear-btn-top};
  --#{$prefix}form-outline-select-clear-btn-right: #{$form-outline-select-clear-btn-right};
  --#{$prefix}form-outline-select-clear-btn-focus-color: #{$form-outline-select-clear-btn-focus-color};
  --#{$prefix}form-outline-select-sm-clear-btn-font-size: #{$form-outline-select-sm-clear-btn-font-size};
  --#{$prefix}form-outline-select-sm-clear-btn-top: #{$form-outline-select-sm-clear-btn-top};
  --#{$prefix}form-outline-select-lg-clear-btn-top: #{$form-outline-select-lg-clear-btn-top};
  --#{$prefix}form-outline-select-label-max-width: #{$form-outline-select-label-max-width};
  --#{$prefix}form-outline-select-label-active-transform: #{$form-outline-select-label-active-transform};
  --#{$prefix}form-outline-select-lg-label-active-transform: #{$form-outline-select-lg-label-active-transform};
  --#{$prefix}form-outline-select-sm-label-active-transform: #{$form-outline-select-sm-label-active-transform};
  --#{$prefix}form-outline-select-input-focused-color: #{$form-outline-select-input-focused-color};
  --#{$prefix}form-outline-select-label-color: #{$form-outline-select-label-color};
  --#{$prefix}form-outline-select-notch-border-color: #{$form-outline-select-notch-border-color};
  --#{$prefix}form-outline-select-white-notch-border-color: #{$form-outline-select-white-notch-border-color};
  --#{$prefix}form-outline-select-input-focused-arrow-color: #{$form-outline-select-input-focused-arrow-color};
  --#{$prefix}form-outline-select-white-focus-arrow-color: #{$form-outline-select-white-focus-arrow-color};
  --#{$prefix}form-outline-select-white-arrow-color: #{$form-outline-select-white-arrow-color};
  --#{$prefix}form-outline-select-white-clear-btn: #{$form-outline-select-white-clear-btn};
  --#{$prefix}form-outline-select-sm-arrow-top: #{$form-outline-select-sm-arrow-top};
  --#{$prefix}form-outline-select-lg-arrow-top: #{$form-outline-select-lg-arrow-top};
  --#{$prefix}form-outline-form-notch-border-top: #{$form-outline-form-notch-border-top};
}